<template>
    <div>
         <div style="
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 1.25rem;
      margin-left: 1.25rem;
    ">
    
    <!-- <el-row style="margin:20px 0">
       <div style="padding-bottom:20px;display:flex;align-items:center">
          <el-button type="success" style="margin-left:20px" @click="exportToExcel">导出用户推荐关系文件</el-button>
        </div>
    </el-row> -->
    <el-row style="margin:20px 0;display:flex">
        <el-input placeholder="输入用户UID" v-model="userId" style='width:400px;margin-right:20px'></el-input>
         <el-button type="primary" style="margin-left:20px" @click="getList">查询</el-button>
    </el-row>
    <el-tree :data="list" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
    </div>
    </div>
</template>


<script>

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
export default {
   data(){
    return{
        form:[],
        total:0,
        pageSize:10,
        currentPage:1,
        userId:'',
         defaultProps: {
          children: 'childList',
          label: 'label'
        },
        list:[]
    }
   },
   methods:{
    handleNodeClick(){

    },
    getList(){
      this.axios.get('admin/user/findChild',{userId:this.userId})
      .then(res=>{
        console.log(res)
        this.list=[res.data.data]
        this.list[0].label=`UID:${this.list[0].uid}-手机号:${this.list[0].tel}`
        this.list[0].childList.map(item=>{
            item.label=`UID:${item.uid}-手机号:${item.tel}-推荐人UID:${item.parentId}`
        })
        console.log(this.list)

      })
    },
    exportToExcel() {
      this.$message.success('生成中，请稍候');
      findUserParent({ 
          // genId:this.formData.genId,
          // status:this.formData.status,
          // userId:this.formData.userId,
          // startTime: this.formData.time[0],
          // endTime: this.formData.time[1],
          })
          .then(()=>{
            setTimeout(() => {
              getUserParent()
              .then(res=>{
                  // 假设你有一个表格数据的数组

            let data = [
                              ["备注", "用户ID", "推荐人ID"],
                              
                          ];
                          let arr=res.data
                          arr.map((item,i)=>{
                              data.push(['',item.userId,item.parentId])
                          })
      
            // 将数据转换为工作表
            const worksheet = XLSX.utils.aoa_to_sheet(data);
      
            // 创建工作簿并添加工作表
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      
            // 生成Excel文件并导出
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
            saveAs(dataBlob, '推荐关系.xlsx');
              })
             
            }, 2000);
             
       })
          
      
    },
   }
}
</script>

<style>
</style>